import './App.less';
import {Provider as InversifyProvider} from 'inversify-react';
import {Provider, useDispatch} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, {RoutingPaths} from "./pages/routing/routingContainer";
import React, {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import ApiService from "./services/apiService";
import SiderMenu from './components/siderMenu/siderMenu';
import HeaderContainer from './components/header/headerContainer';
import FooterContainer from './components/footer/footerContainer';
import {CrudBreadcrumb, CrudLayout} from 'digimuth-components/dist';
import {Container} from 'inversify';
import {jwtDecode} from 'jwt-decode';
import {ConfigProvider} from "antd";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {setCurrentUser} from "./redux/applicationUserSlice";
import useCurrentUserRoles from "./hooks/useCurrentUserRoles";
import plPL from "antd/locale/pl_PL";
import {ConfigProviderProps} from 'antd/es/config-provider';

function App() {
    return (
        /* // @ts-ignore */
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: "https://localhost:5001",
                scope: "manage:users email profile"
            }}
        >
            <Provider store={store}>
                <AppInternal/>
            </Provider>
        </Auth0Provider>
    )
}

const container = new Container();

function AppInternal() {

    const auth = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth.isLoading) {
            auth.getAccessTokenSilently().then(t => {
                container.bind(ApiService).toDynamicValue(() => new ApiService(t)).inSingletonScope();
                auth.getIdTokenClaims().then(c => {
                    dispatch(setCurrentUser({email: c!.email!, roles: (jwtDecode(t) as any).permissions || []}));
                });
            });
        }
    }, [auth.isLoading, dispatch, auth]);

    return <InversifyProvider container={container}>
        <BrowserRouter>
            <Layout/>
        </BrowserRouter>
    </InversifyProvider>
}

const CrudDefaultTheme = {
    "token": {
        "colorPrimary": "#9c753d",
        "colorInfo": "#9c753d",
        "colorSuccess": "#989c3d",
        "colorError": "#c20c3a",
        "colorWarning": "#c29232",
        "wireframe": false,
        "borderRadius": 10,
        "sizeStep": 5,
        "sizeUnit": 4,
        "fontSize": 14,
        "colorBgBase": "#ffffff",
        "colorBgLayout": "#ffffff",
        "colorTextBase": "#151429",
        "controlHeight": 36,
        "controlHeightSM": 28
    },
    "components": {
        "Layout": {
            // "siderBg": "#5F5C63",
            "triggerBg": "#36326d"
        }
    }
}

const antdConfig: ConfigProviderProps = {
    theme: CrudDefaultTheme,
    locale: plPL
}

function Layout() {
    const auth = useAuth0();
    const roles = useCurrentUserRoles();
    const [showSider, setShowSider] = useState<boolean>(window.innerWidth > 576);

    useEffect(() => {
        const resize = () => {
            const shouldShowSider = window.innerWidth > 576;
            if (shouldShowSider !== showSider) setShowSider(shouldShowSider);
        }
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [showSider]);

    return <ConfigProvider {...antdConfig}>
        <CrudLayout
            siderFullHeight={true}
            breadcrumb={<CrudBreadcrumb routingDefinitions={RoutingPaths}/>}
            siderMenu={<SiderMenu/>}
            header={<HeaderContainer/>}
            footerContent={<FooterContainer/>}
            showSider={auth.isAuthenticated && roles !== undefined && roles.length > 0 && showSider}
            configProviderProps={antdConfig}
            siderProps={{theme: "light"}}
        >
            <RoutingContainer/>
        </CrudLayout>
    </ConfigProvider>
}

export default App;
