import {
    BarcodeOutlined, CalculatorOutlined, ContactsOutlined,
    DatabaseOutlined,
    OrderedListOutlined,
    SettingOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../pages/routing/routingContainer";
import styles from "./siderMenu.module.less";
import {useCurrentRoutingPath} from "digimuth-components/dist";
import logo from "../../assets/logo.png";
import {ReactElement} from "react";

export interface MenuItem {
    displayName: string,
    route: string,
    icon: ReactElement;
}

const MenuItems: MenuItem[] = [
    {
        displayName: "Użytkownicy",
        route: RoutingPaths.userTable.route,
        icon: <TeamOutlined/>
    },
    {
        displayName: "Magazyny",
        route: RoutingPaths.warehouseTable.route,
        icon: <DatabaseOutlined/>
    },
    {
        displayName: "Produkty",
        route: RoutingPaths.productTable.route,
        icon: <BarcodeOutlined/>
    },
    {
        displayName: "Operacje magazynowe",
        route: RoutingPaths.stockOperationTable.route,
        icon: <OrderedListOutlined/>
    },
    {
        displayName: "Operacje produkcyjne",
        route: RoutingPaths.productionStockOperationAddNoParams.route,
        icon: <OrderedListOutlined/>
    },
    {
        displayName: "Inwentaryzacje",
        route: RoutingPaths.inventoryOperationTable.route,
        icon: <OrderedListOutlined/>
    },
    {
        displayName: "Jednostki miary",
        route: RoutingPaths.unitOfMeasureTable.route,
        icon: <CalculatorOutlined/>
    },
    {
        displayName: "Ustawienia",
        route: RoutingPaths.companyData.route,
        icon: <SettingOutlined/>
    },
    {
        displayName: "Kontrahenci",
        route: RoutingPaths.contractorTable.route,
        icon: <ContactsOutlined/>
    }
];

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

    return <>
        <NavLink to={"/"}>
            <img alt={"logo"} src={logo} className={styles.logo}/>
        </NavLink>
        <Menu theme="dark" selectedKeys={[route]} mode="inline"
              items={MenuItems.map((item: MenuItem) => {
                  return {
                      key: item.route,
                      icon: item.icon,
                      label: <NavLink to={item.route}>
                          {item.displayName}
                      </NavLink>
                  }
              })}
        />
    </>;
}

export {MenuItems}