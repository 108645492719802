import HeaderComponent from "./header";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

export default function HeaderContainer() {
    const auth = useAuth0();
    const navigate = useNavigate();

    const onLogoutPressed = async () => {
        await auth.logout({logoutParams: {returnTo: process.env.REACT_APP_URL}});
    }

    const onLoginPressed = async () => {
        navigate("/login");
    }

    return <HeaderComponent
        onLogoutPressed={onLogoutPressed}
        userData={auth.user}
        onLoginPressed={onLoginPressed}
    />
}
