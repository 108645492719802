import {CalendarOutlined, HomeOutlined} from '@ant-design/icons';
import React from 'react';
import styles from './headerComponent.module.less';
import {User} from "@auth0/auth0-spa-js";
import {useNavigate} from "react-router-dom";
import {RoutingPaths} from "../../pages/routing/routingContainer";
import Logo from "../../assets/logo.png";
import {CrudHeader} from "digimuth-components/dist";
import {MenuItems} from "../siderMenu/siderMenu";

interface HeaderComponentProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

export default function HeaderComponent(props: HeaderComponentProps) {
    const navigate = useNavigate();

    const onCalendarClicked = () => {
        navigate(RoutingPaths.warehouseOperationsCalendar.route);
    }

    return <CrudHeader
        routingPaths={RoutingPaths}
        userName={props.userData?.name}
        headerChildren={<CalendarOutlined onClick={onCalendarClicked} className={styles.calendarIcon}/>}
        mobileLogo={<img src={Logo} alt={"logo"} style={{width: "150px"}}/>}
        logo={<img src={Logo} alt={"logo"} style={{width: "100px"}}/>}
        upperMenuItems={[{
            displayName: "Strona główna",
            route: RoutingPaths.mainPage.route,
            icon: <HomeOutlined/>
        }]}
        mainMenuItems={MenuItems}
        mobileMenuStyles={{header: {backgroundColor: "#001529"}}}
        lowerMenuItems={[{
            displayName: "Kalendarz",
            route: RoutingPaths.warehouseOperationsCalendar.route,
            icon: <CalendarOutlined/>
        }]}
        onLogout={props.onLogoutPressed}
        mainColor={"#9c753d"}
    />
}
