import {ReactElement, Suspense, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useCurrentUserRoles from "../../hooks/useCurrentUserRoles";
import {loadingSpin, RoutingPaths} from "./routingContainer";
import {useAuth0} from "@auth0/auth0-react";

export function AuthInitialized(props: { children: ReactElement, loadingIndicator: ReactElement }) {
    const auth = useAuth0();

    if (auth.isLoading) {
        return props.loadingIndicator || null;
    }

    return <Suspense fallback={loadingSpin}>
        {props.children}
    </Suspense>;
}

export function Protected(props: {
    children: ReactElement,
    loadingIndicator: ReactElement,
    allowedForRoles?: string[]
}) {
    const auth = useAuth0();
    const navigate = useNavigate();
    const roles = useCurrentUserRoles();

    useEffect(() => {
        if (!roles) {
            return;
        }

        if (props.allowedForRoles && !roles.some(r => props.allowedForRoles!.includes(r))) {
            navigate(RoutingPaths.mainPage.route, {replace: true});
        }
    }, [roles, props.allowedForRoles, navigate]);

    if (auth.isLoading) {
        return props.loadingIndicator;
    }

    if (!auth.isAuthenticated) {
        auth.loginWithRedirect();

        return props.loadingIndicator;
    }

    return <Suspense fallback={loadingSpin}>
        {props.children}
    </Suspense>;
}